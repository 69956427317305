import request from '@/utils/request'

const getProductDetail = (id) => {
  return request({
    url: `/school/products/${id}`,
    method: 'get'
  })
}

const getProductList = (params) => {
  return request({
    url: '/school/products',
    params: params,
    method: 'get'
  })
}

const getProductCount = (params) => {
  return request({
    url: '/school/products/count',
    params: params,
    method: 'get'
  })
}

const changeState = (id, params) => {
  return request({
    url: `/school/products/${id}/shelves`,
    data: params,
    method: 'put'
  })
}
const getStoreList = (params) => {
  return request({
    url: `/open/stores`,
    params,
    method: 'get'
  })
}
const getStoreLength = () => {
  return request({
    url: `/open/stores/count`,
    method: 'get'
  })
}
export {
  getProductDetail, // 获取商品详情
  getProductList, // 获取商品列表
  getProductCount, // 获取商品数量
  changeState, // 上下架状态
  getStoreList, // 获取供应商列表
  getStoreLength // 获取供应商数据
}
