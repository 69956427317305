<template>
  <div>
    <a-upload-dragger
      name="file"
      :multiple="true"
      action="http://www.lexiangspace.com/api/sys/fs"
      :fileList="fileList"
      :disabled="disabled"
      :headers="headers"
      :accept="accept"
      :beforeUpload="beforeUpload"
      @preview="handlePreview"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">支持单个或批量上传</p>
      <p class="ant-upload-hint">建议上传大小不超过{{size}}M的文件</p>
    </a-upload-dragger>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { getFileName } from '@/serve/file'
import global from 'global'

export default {
  data () {
    return {
      headers: {},
      previewVisible: false,
      previewImage: '',
      fileIds: this.fileId,
      initWatch: true,
      fileList: [],
      imageFormats: ['PNG', 'JPEG', 'GIF', 'JPG']
    }
  },
  props: {
    fileId: {
      type: String
    },
    multipleNmber: {
      default: 1,
      type: Number
    },
    action: {
    },
    accept: {
      default: global.fileTypeMimes.Attach
    },
    btnText: {
      default: '上传文件',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    size: {
      default: 2,
      type: Number
    }
  },
  model: {
    prop: 'fileId'
  },
  created () {
    this.setValToInit()
  },
  watch: {
    'fileId': function (newVal, oldVal) {
      if (newVal && this.initWatch) {
        this.setValToInit()
      }
    }
  },
  methods: {
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      let suffix = file.name.split('.').pop()
      suffix = suffix ? suffix.toUpperCase() : ''
      if (this.imageFormats.indexOf(suffix) > -1) {
        this.previewImage = file.url || file.thumbUrl
        this.previewVisible = true
      } else {
        window.open(file.url || file.thumbUrl)
      }
    },
    handleChange (info) {
      if (info.file.status === undefined) {
        return
      }
      this.initWatch = false
      let fileList = [...info.fileList]
      fileList = fileList.slice(-this.multipleNmber)

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        file.linkProps = {
          download: 'image'
        }
        if (file.response) {
          file.url = `${global.file.url || this.action}/${file.response.id}`
        }
        return file
      })

      this.fileList = fileList
      if (info.file.status === 'done') {
        this.setValToModel(this.fileList)
      }
    },
    setValToModel (fileList) {
      let value = ''
      for (let j = 0; j < fileList.length; j++) {
        if (fileList[j].response != null) {
          if (j > 0) {
            value += ','
          }
          value += fileList[j].response.id
        }
      }
      this.$emit('input', value)
    },
    setValToInit () {
      if (!this.fileId) {
        return
      }
      let fileList = []
      getFileName(this.fileId).then((files) => {
        for (let i = 0; i < files.length; i++) {
          files[i] && fileList.push({
            uid: files[i].id,
            status: 'done',
            name: files[i].name,
            response: {
              id: files[i].id,
              name: files[i].name
            },
            url: `${global.file.url}/${files[i].id}`
          })
        }
        this.fileList = fileList
      })
    },
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < this.size
      if (!isLt2M) {
        this.$message.error(`请上传大小不超过${this.size}M的文件`)
      }
      return isLt2M
    }
  }
}
</script>

<style>
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px;
}
</style>
