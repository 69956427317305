<template>
  <div class="box product-detail">
    <div v-show="step === 1">
      <a-form :form="form" layout="inline">
        <div class="table-style-product">
          <div class="title">
            <span>{{ id === "0" ? "商品基础信息录入" : "商品基础信息编辑" }}</span>
          </div>
          <div class="form-cont">
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品名称" class="form-item-label items">
                  <a-input
                    class="unityWidth"
                    style="width: 280px"
                    placeholder="商品名称长度不大于128个汉字"
                    v-decorator="[
                      'name',
                      { rules: [{ required: true, message: '请输入商品名称' }], initialValue: product.name }
                    ]"
                  ></a-input>
                </a-form-item>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item
                  label="商品类型"
                  class="form-item-label items"
                  :label-col="{ span: 5 }"
                  :wrapper-col="{ span: 12 }"
                >
                  <a-cascader
                    style="width: 280px"
                    changeOnSelect
                    :options="options"
                    @change="onChange"
                    placeholder="请输入商品类型"
                    v-decorator="[
                      'categoryId',
                      { rules: [{ required: true, message: '请输入商品类型' }], initialValue: categoryIdList }
                    ]"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="买方类型">
                  <a-select
                    mode="multiple"
                    placeholder="请选择买方类型"
                    style="width: 280px"
                    v-decorator="[
                      'shoperType',
                      { rules: [{ required: true, message: '请选择买方类型' }], initialValue: product.shoperType }
                    ]"
                    @change="handleChange"
                    @popupScroll="popupScroll"
                  >
                    <!-- <a-select-option v-for="i in shoperTypeList" :key="i">{{i}}</a-select-option> -->
                    <a-select-option value="TEACHER">老师</a-select-option>
                    <a-select-option value="STUDENT">学生</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="年龄层次">
                  <a-select
                    mode="multiple"
                    placeholder="请选择年龄层次"
                    style="width: 280px"
                    v-decorator="[
                      'ageStage',
                      { rules: [{ required: true, message: '请选择买方类型' }], initialValue: product.ageStage }
                    ]"
                    @change="handleChange"
                    @popupScroll="popupScroll"
                  >
                    <a-select-option value="KG">幼儿园</a-select-option>
                    <a-select-option value="PS">小学</a-select-option>
                    <a-select-option value="JS">初中</a-select-option>
                    <a-select-option value="HS">高中</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <a-form-item label="供货给">
                  <a-select
                    id="mySelect"
                    mode="multiple"
                    placeholder="请选择学校"
                    style="width: 280px"
                    v-decorator="['blocTagBlocId', { initialValue: product.blocTagBlocId }]"
                    @change="schoolSelect"
                    @popupScroll="popupScroll"
                  >
                    <!-- v-decorator="['schoolBuySchoolId',{rules: [{ required: true, message: '请选择买方类型' }], initialValue: product.schoolBuySchoolId}]" <a-select-option v-for="i in shoperTypeList" :key="i">{{i}}</a-select-option> -->
                    <!-- <a-select-option value="true">全选</a-select-option> -->
                    <a-select-option v-for="item in schoolList" :key="item.id" :value="item.id"
                      >供货{{ item.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品图片" class="items" required>
                  <!-- <div class="clearfix" style="width: 830px;">
                    <upload-pic class="upload-pic" v-model="pic" :multipleNmber="5" :size="1"></upload-pic>
                    <div class="upload-pic-info">
                      <a-icon type="info-circle" class="colorYellow" />支持jpg、gif、png格式上传，建议使用尺寸800*800像素以上，大小不超过1M的正方形图片(限五张)
                    </div>
                  </div>-->
                  <div class="clearfix pic-box">
                    <a-upload
                      :multiple="true"
                      action="http://www.lexiangspace.com/api/sys/fs"
                      listType="picture-card"
                      :fileList="fileList"
                      @preview="handlePreview"
                      @change="picChange"
                    >
                      <div v-if="fileList.length < 5">
                        <a-icon type="plus" />
                        <div class="ant-upload-text">Upload</div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="example" style="width: 100%" :src="previewImage" />
                    </a-modal>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                <a-form-item label="商品视频" class="items">
                  <div class="clearfix pic-box">
                    <a-upload
                      :multiple="false"
                      :fileList2="fileList"
                      action="http://www.lexiangspace.com/api/sys/fs"
                      @change="videoChange"
                    >
                      <a-button> <a-icon type="upload" /> 上传视频 </a-button>
                      <p>注:视频大小限制为50M</p>
                    </a-upload>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <a-form-item label="商品详情" class="items" required>
                  <tinymce-editor v-if="id === '0' || (id !== '0' && product.name)" v-model="content"></tinymce-editor>
                  <!-- <tinymce-editor v-model="content"></tinymce-editor> -->
                </a-form-item>
              </a-col>
            </a-row>
            <!-- <a-row>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <a-form-item label="规格数据" class="items" required>
                  <tinymce-editor-single v-model="propertyHtml"></tinymce-editor-single>
                </a-form-item>
              </a-col>
            </a-row>-->
            <a-row>
              <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <a-form-item label="规格数据" class="items spaces-box" required>
                  <div style="display: flex;">
                    <div style="border: 1px solid #e8e8e8;padding: 0 5px;border-radius: 4px;">
                      <div style="text-align: center;line-height: 33px;border-bottom: 1px solid #e8e8e8;">规格</div>
                      <a-input v-model="guige"></a-input>
                    </div>
                    <a-table :columns="columns" :dataSource="spacesData" :pagination="false" rowKey="id" size="small">
                      <template slot="value" slot-scope="text, record">
                        <a-input
                          key="value"
                          style="margin: -5px 0"
                          :value="text"
                          placeholder="套餐名称"
                          @change="e => spacesHandle(e.target.value, record.id, 'value')"
                        />
                      </template>
                      <template slot="picDef" slot-scope="text, record" class="picDef-fileList-class">
                        <a-upload
                          :action="actions"
                          listType="picture-card"
                          v-if="spacesData[record.id - 1]"
                          :fileList="spacesData[record.id - 1].picDefFileList"
                          @preview="handlePreview"
                          @change="picDefChange"
                        >
                          <div v-if="spacesData[record.id - 1].picDefFileList.length < 1">
                            <a-icon type="plus" />
                            <div class="ant-upload-text">上传图片</div>
                          </div>
                        </a-upload>
                        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                          <img alt="example" style="width: 100%" :src="previewImage" />
                        </a-modal>
                      </template>
                      <template slot="unitPrice" slot-scope="text, record">
                        <a-input
                          key="unitPrice"
                          style="margin: -5px 0"
                          :value="text"
                          placeholder="实际售价"
                          @change="e => spacesHandle(e.target.value, record.id, 'unitPrice')"
                        />
                      </template>
                      <template slot="unitPriceShow" slot-scope="text, record">
                        <a-input
                          key="unitPriceShow"
                          style="margin: -5px 0"
                          :value="text"
                          placeholder="原价"
                          @change="e => spacesHandle(e.target.value, record.id, 'unitPriceShow')"
                        />
                      </template>
                      <template slot="num" slot-scope="text, record">
                        <a-input
                          key="num"
                          style="margin: -5px 0"
                          :value="text"
                          placeholder="库存量"
                          @change="e => spacesHandle(e.target.value, record.id, 'num')"
                        />
                      </template>
                      <template slot="operation" slot-scope="text, record">
                        <span>
                          <a @click="remove(record.id)">删除</a>
                        </span>
                      </template>
                    </a-table>
                  </div>
                  <a-button class="mb10" size="small" type="primary" @click="coreAdd">
                    <a-icon type="plus" />添加套餐类型
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="footer-btn-bottom">
          <a-button type="primary" @click="next" class="mr10">确认并保存</a-button>
          <!-- <a-button>返回</a-button> -->
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
// import UploadPic from '@/components/upLoader/UploadPic'
import TinymceEditor from '@/components/Editor/TinymceEditor'
// import TinymceEditorSingle from '@/components/Editor/TinymceEditorSingle'
import {  getProductDetail } from '@/serve/product'
import { getBlocs } from '@/serve/open'
import global from 'global'
import { getCategory, getCategoryDetail } from '@/serve/category'
export default {
  components: {
    // UploadPic
    TinymceEditor
    // TinymceEditorSingle
  },
  data () {
    return {
      form: this.$form.createForm(this),
      id: this.$route.params.id || '0',
      step: 1,
      product: {},
      wrapperCol: {
        sm: { span: 20 },
        md: { span: 20 },
        lg: { span: 20 },
        xl: { span: 20 }
      },
      options: [],
      columns: [
        {
          title: '套餐名称',
          dataIndex: 'value',
          key: 'value',
          scopedSlots: { customRender: 'value' }
        },
        {
          title: '套餐图片',
          dataIndex: 'picDef',
          scopedSlots: { customRender: 'picDef' }
        },
        {
          title: '实际售价',
          dataIndex: 'unitPrice',
          key: 'unitPrice',
          scopedSlots: { customRender: 'unitPrice' }
        },
        {
          title: '原价',
          dataIndex: 'unitPriceShow',
          key: 'unitPriceShow',
          scopedSlots: { customRender: 'unitPriceShow' }
        },
        {
          title: '库存',
          dataIndex: 'num',
          key: 'num',
          scopedSlots: { customRender: 'num' }
        },
        {
          title: '操作',
          key: 'action',
          width: '10%',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      spacesData: [
        {
          id: 1,
          value: '',
          picDefFileList: [],
          unitPrice: '',
          unitPriceShow: '',
          num: ''
        }
      ],
      previewVisible: false,
      previewImage: '',
      fileList: [],
      fileList2: [],
      path: '',
      content: '',
      propertyHtml: '',
      guige: '',
      categroyStr: '', // 商品分类
      categoryIdList: [], // 商品分类
      schoolList: [],
      schoolSelected: ''
      // pic: ''
    }
  },
  computed: {
    actions () {
      return global.file.url + '/sys/fs/'
    }
  },
  methods: {
    coreAdd () {
      if (this.spacesData.length === 0 || this.spacesData[this.spacesData.length - 1].value !== '') {
        const length = this.spacesData.length
        this.spacesData.push({
          id: length === 0 ? 0 : this.spacesData[length - 1].id + 1,
          value: '',
          picDefFileList: [],
          unitPrice: '',
          unitPriceShow: '',
          num: ''
        })
      }
    },
    spacesHandle (value, id, column) {
      const newData = [...this.spacesData]
      const target = newData.filter(item => id === item.id)[0]
      if (target) {
        target[column] = value
        this.spacesData = newData
      }
    },
    remove (id, type) {
      const newData = this.spacesData.filter(item => item.id !== id)
      this.spacesData = newData
      if (this.spacesData.length === 0) {
        this.spacesData.push({
          id: 0,
          value: '',
          picDefFileList: [],
          unitPrice: '',
          unitPriceShow: '',
          num: ''
        })
      }
    },
    // 获取学校列表
    async getMySchool () {
      this.schoolList = await getBlocs()
      // console.log(document.getElementById('mySelect'))
      // this.schoolList.map(item => {
      //   let select = ` <a-select-option value="${item.name}">${item.name}</a-select-option>`
      //   document.getElementById('mySelect').push(select)
      // })
    },
    // 获取照片
    getPhoto (id) {
      if (!id) {
        return false
      } else if (id.indexOf(',') > -1) {
        let reg = new RegExp(',')
        let ids = id.replace(reg, '')
        return global.file.url + '/sys/fs/' + ids
      } else {
        return global.file.url + '/sys/fs/' + id
      }
    },
    // 商品分类
    onChange (val) {
      this.categroyStr = val[val.length - 1]
    },
    handleChange (value) {
      console.log(`Selected: ${value}`)
    },
    // 选择学校
    schoolSelect (val) {
      this.schoolSelected = val
    },
    popupScroll () {
      console.log('popupScroll')
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    picChange ({ fileList }) {
      this.fileList = fileList
    },
    picDefChange ({ fileList }) {
      this.spacesData[this.spacesData.length - 1].picDefFileList = fileList
    },
    videoChange ({ fileList, event }) {
      this.fileList2 = fileList
      console.log(this.fileList2[0], event, 'this.fileList2!!!!!')
    },
    // 下一步
    next (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.spaces = []
          if (this.fileList2.length >= 1) {
            values.video = this.fileList2[0].response.id
            console.log('是否上传视频')
          }
          this.spacesData.forEach(item => {
            values.spaces.push({
              num: item.num,
              unitPrice: item.unitPrice,
              picDef: item.picDefFileList[0].response ? item.picDefFileList[0].response.id : item.picDefFileList[0].id,
              unitPriceShow: item.unitPriceShow,
              properties: [
                {
                  name: this.guige,
                  value: item.value
                }
              ]
            })
          })

          let picList = []
          this.fileList.forEach(item => {
            if (item.response) {
              picList.push(item.response.id)
            } else {
              picList.push(item.id)
            }
          })
          values.pic = picList.join(',')

          // values.schoolBuyAll = this.schoolSelected.includes('true')
          values.categoryId = this.categroyStr // 商品类型
          values.schoolBuyAll = true
          let temp = []
          this.schoolSelected.forEach(item => {
            if (item !== 'true' && item !== '') {
              temp.push(item)
            }
          })
          values.blocTagBlocId = temp.join(',')
          values.ageStage = values.ageStage.join(',')
          values.shoperType = values.shoperType.join(',')
          values.contentPc = this.content // 商品详情
          // if (values.schoolBuySchoolId !== true) {
          //   values.schoolBuySchoolId = values.schoolBuySchoolId.join(',')
          //   values.schoolBuyAll = false
          // } else {
          //   values.schoolBuyAll = true
          // }
          values.propertyHtml = this.propertyHtml
          if (this.id === '0') {
            addProduct(values)
              .then(res => {
                this.$message.success('录入成功')
                this.$router.push({ path: '/goodsList' })
              })
              .catch(() => {
                this.$message.error('操作失败')
              })
          } else {
            modifyProduct(this.id, values)
              .then(res => {
                this.$message.success('修改成功')
                this.$router.push({ path: '/goodsList' })
              })
              .catch(() => {
                this.$message.error('操作失败')
              })
          }
        }
      })
    },
    // 获取分类信息
    getCategory () {
      getCategory({ idParent: '0' }).then(res => {
        res.forEach(item => {
          item.value = item.id
          item.label = item.name
          if (item.childrenNum > 0) {
            getCategory({ idParent: item.id }).then(res1 => {
              res1.forEach(item1 => {
                item1.value = item1.id
                item1.label = item1.name
              })
              item.children = res1
            })
          }
        })
        this.options = res
      })
    },
    // 初始化信息
    get () {
      if (this.id !== '0') {
        getProductDetail(this.id).then(res => {
          this.product = res
          this.categroyStr = res.categoryId
          getCategoryDetail(res.categoryId).then(res => {
            if (res.parentCategory) {
              this.categoryIdList = [res.parentCategory.id, res.id]
            } else {
              this.categoryIdList = [res.id]
            }
          })
          this.product.shoperType = res.shoperType.split(',')
          this.product.ageStage = res.ageStage.split(',')
          this.schoolSelected = res.blocTagBlocId.split(',')
          // if (res.schoolBuyAll) {
          //   this.product.schoolBuySchoolId = ['true']
          // } else {
          //   this.product.blocTagBlocId = res.blocTagBlocId.split(',')
          // }
          this.product.blocTagBlocId = res.blocTagBlocId.split(',')
          if (res.pic) {
            res.pic.split(',').forEach((item, index) => {
              this.fileList.push({
                uid: (index + 1) * -1,
                id: item,
                name: 'photo.jpg',
                status: 'done',
                url: this.getPhoto(item)
              })
            })
          }
          this.spacesData = []
          res.spaces.forEach((item, index) => {
            this.spacesData.push({
              id: index + 1,
              value: item.properties[0].value,
              picDefFileList: [
                {
                  uid: -1,
                  id: item.picDef,
                  name: 'photo.jpg',
                  status: 'done',
                  url: this.getPhoto(item.picDef)
                }
              ],
              unitPrice: item.unitPrice,
              unitPriceShow: item.unitPriceShow,
              num: item.num
            })
          })
          this.content = res.contentPc
          this.guige = res.spaces[0].properties[0].name
        })
      }
    }
  },
  created () {
    this.get()
    this.getCategory()
  },
  mounted () {
    this.getMySchool()
  }
}
</script>

<style lang="less">
.table-style-product {
  border: 1px solid #ccc;
  .title {
    line-height: 40px;
    padding: 0 16px;
    font-size: 16px;
    background: #f1f1f1;
    border-bottom: 1px solid #ccc;
  }
  .form-cont {
    > .ant-row {
      padding: 6px 0;
      border-bottom: 1px solid #ccc;
      /* .form-item-label{
        .ant-form-item-label{
          border-right: 1px solid #ccc;
          margin-right: 6px;
        }
      } */
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-form-item-label label {
        font-weight: bold;
      }
    }
    .ant-form-item-label {
      width: 120px;
    }
    .core-label > .ant-form-item-label {
      width: 80px;
    }
  }

  .children-items .ant-form-item-label {
    width: 80px;
  }
  .pic-box > span {
    display: flex;
  }
}
.spaces-box .ant-form-item-control-wrapper {
  width: 80%;
}
.footer-btn-bottom {
  text-align: center;
  margin: 20px 0;
}

.ant-table-content {
  .ant-upload.ant-upload-select-picture-card {
    width: 70px;
    height: 70px;
  }
  .ant-upload-list-picture-card {
    .ant-upload-list-item {
      width: 70px;
      height: 70px;
    }
  }
}
</style>
